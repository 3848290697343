import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/SEO"
import styles from "../css/privacy-css-modules.module.css"
import Layout from "../components/layout/Layout"
import { 
    SectionWrapper,
    SectionWrapperGray, 
    InnerWrapper, 
    SectionSubHeader 
} from "../components/Wrapper"


const BlogPage = ({ data }) => {
    var showdown = require('showdown'),
        converter = new showdown.Converter();

    return (
        <Layout>
            <SEO
                title={`All Ins Agency - Privacy Policy`}
                description={`All Ins Agency`}
            />
            <SectionWrapperGray className="mt-8">
                <InnerWrapper className="my-12">
                    <SectionSubHeader>Privacy Policy</SectionSubHeader>
                </InnerWrapper>
            </SectionWrapperGray>
            <SectionWrapper>
                <InnerWrapper className="my-12">
                    <div className={styles.privacyWrapper}
                        dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }}
                    />
                </InnerWrapper>
            </SectionWrapper>
        </Layout >
    )
}

export default BlogPage

export const privacyQuery = graphql`
    query PrivacyQuery {
        allStrapiPage(filter: {name: {eq: "privacyPolicy"}}) {
            edges {
                node {
                    name
                    body
                }
            }
        }
        site {
            siteMetadata { 
                apiUrl
            }
        }
    }
`